import SectionMessage from '@atlaskit/section-message';
import BenefitModalBasicExample from "../../../../../../packages/design-system/onboarding/examples/constellation/benefits-modal-default";
import ModalDialogExample from "../../../../../../packages/design-system/onboarding/examples/constellation/benefits-modal-using-modal-dialog";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHbenefits_modal_default from "!!raw-loader!../../../../../../packages/design-system/onboarding/examples/constellation/benefits-modal-default";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHbenefits_modal_using_modal_dialog from "!!raw-loader!../../../../../../packages/design-system/onboarding/examples/constellation/benefits-modal-using-modal-dialog";
import * as React from 'react';
export default {
  SectionMessage,
  BenefitModalBasicExample,
  ModalDialogExample,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHbenefits_modal_default,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHbenefits_modal_using_modal_dialog,
  React
};